<template>
  <v-row class="px-4">
    <v-col
      v-for="field in parsedPreview"
      :key="field.name"
      :cols="field.col"
    >
      <div class="input-label">
        {{ field.text }}
      </div>
      <div class="input-value">
        {{ field.value }}
      </div>
    </v-col>
    <v-col cols="12">
      <slot />
    </v-col>
    <v-col cols="12">
      <span class="input-label">Składowe dokumentu</span>
      <DetailsSectionInvoiceItems
        class="mx-n2"
        :data="preview"
        :is-net-value-parsed="true"
        :detail="invoiceItemsHeaders"
      />
    </v-col>
    <v-col cols="12">
      <span class="input-label mb-1">Zlecenia</span>
      <DetailsSectionInvoiceCoursesTable :data="preview" />
    </v-col>
  </v-row>
</template>

<script>
import DetailsSectionInvoiceItems from '../../../components/Single/Sections/DetailsSectionInvoiceItems.vue'
import DetailsSectionInvoiceCoursesTable from '../../../components/Single/Sections/DetailsSectionInvoiceCoursesTable.vue'
import { formatValue } from '../../../utils/typesEnum'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { getTableDetails } from '../../../utils'

export default {
  props: {
    preview: {
      type: Object,
      required: true
    },
    isCustomServiceInvoice: {
      type: Boolean,
      defaul: false
    }
  },

  components: {
    DetailsSectionInvoiceItems,
    DetailsSectionInvoiceCoursesTable
  },

  computed: {
    previewConfig() {
      return [
        { name: 'invoiceNumber', text: 'Nr dokumentu', value: 'invoiceNumber', col: 3 },
        { name: 'nip', text: 'Nr NIP', value: 'nip', col: 3 },
        { name: 'buyerName', text: 'Nabywca', value: 'buyerName', col: 6 },
        { name: 'postalCode', text: 'Kod pocztowy', value: 'postalCode', col: 3 },
        { name: 'city', text: 'Miejscowość', value: 'city', col: 3 },
        { name: 'address', text: 'Adres', value: 'address', col: 6 },
        { name: 'paymentType', text: 'Typ płatności', value: 'paymentType', col: 3 },
        {
          name: 'paymentStatus',
          text: 'Status płatności',
          value: 'paymentStatus',
          col: 3,
          hide: this.isCustomServiceInvoice
        },
        { name: 'netValue', text: 'Wartość netto', value: 'netValue', col: 3 },
        { name: 'grossValue', text: 'Wartość brutto', value: 'grossValue', col: 3 }
      ]
    },
    parsedPreview() {
      const parsePreview = preview => {
        return this.previewConfig
          .filter(field => !field.hide)
          .map(field => {
            const value = get(preview, field.value)
            return {
              ...field,
              value: formatValue(value, field.name)
            }
          })
      }
      return isEmpty(this.preview) ? false : parsePreview(this.preview)
    },
    invoiceItemsHeaders() {
      return getTableDetails('invoices')?.sections.find(section => section.name === 'items')
    }
  }
}
</script>

<style scoped>
.input-value {
  word-break: break-all;
}
</style>
